import { Store } from "pullstate";
import { CREDIT_DEBIT_CARD, MONTHLY } from "../shared/constants";

export const SignUpStore = new Store({
    paymenthMethod: CREDIT_DEBIT_CARD,
    total: 234,
    discount: 0,
    selectedSubs: MONTHLY,
    isValid: "",
    code: "",
    usingChase: false,
    loading: false,
    firstName:"",
    lastName:"",
    email:"",
    phoneNumber:"",
    address:"",
    country:"USA",
    city:"",
    state:"",
    zip:"",
    cctoken:"",
    expiry:"",
    cardHolderName: "",
    acceptTerms: false,
});