import { MONTHLY } from "../shared/constants";
import { SignUpStore } from "../stores/SignUpStore";
import util from "../utils/util";

export default function useSideBar(state) {
  // Function to calculate the total cost
  const calculateTotal = (selectedSubs, discountAmount) => {
    let total = 0;
    if (selectedSubs === MONTHLY) {
      total = 179 + 55 - discountAmount;
    } else {
      total = 2148 + 55 - discountAmount;
    }
    return total;
  };

  // Function to validate the discount code
  const validateDiscountCode = async () => {
    try {
      // Check if the discount code is empty
      if (state.code === "") return false;

      // Make an API request to validate the discount code
      const request = await util.axios.get(`/discount_code/validate/${state.code}/${state.selectedSubs}`);
      const { error, msg, result, data } = request.data;

      if (error) throw msg;

      if (result) {
        // Calculate the discount and update the store if the code is valid
        const disc = state.selectedSubs === MONTHLY ? 179 - data.price : 2148 - data.price;
        document.getElementById("codeResultSuccess").classList.remove("d-none");
        SignUpStore.update(s => {
          s.isValid = result;
          s.total = calculateTotal(s.selectedSubs, disc);
          s.discount = disc;
        });
      } else {
        // Handle invalid discount code
        document.getElementById("codeResultFail").classList.remove("d-none");
        SignUpStore.update(s => {
          s.isValid = result;
          s.total = calculateTotal(s.selectedSubs, 0);
          s.discount = 0;
        });
      }
    } catch (error) {
      // Handle API request errors
      alert(JSON.stringify(error));
    }
  };

  // Function to handle the change of subscription
  const handleSubscriptionChange = (e) => {
    const selectedSubs = parseInt(e.target.value);
    const total = calculateTotal(selectedSubs, state.discount);
    SignUpStore.update(s => {
      s.selectedSubs = selectedSubs;
      s.total = total;
    });
  };

  // Function to handle code input changes
  const handleCodeChange = (e) => {
    const code = e.target.value;
    if (code === "") {
      // Reset code, discount, and total when the code is empty
      document.getElementById("codeResultFail").classList.add("d-none");
      document.getElementById("codeResultSuccess").classList.add("d-none");
      SignUpStore.update(s => {
        s.code = code;
        s.discount = 0;
        s.total = calculateTotal(state.selectedSubs, 0);
      });
    } else {
      // Update the code in the store
      SignUpStore.update(s => {
        s.code = code;
      });
    }
  };

  // Determine if the button should be enabled based on code length
  const isButtonEnabled = state.code === null || state.code === "" || state.code.length < 5;

  return { handleSubscriptionChange, handleCodeChange, validateDiscountCode, isButtonEnabled };
}
