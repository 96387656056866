import { Modal } from "react-bootstrap";

const LoadingModal = ({ show, handleClose }) => {
    return (
        <>
            <Modal centered show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="p-5">
                        <h2 className="text-center"><p>Payment Processing</p></h2>
                    <div className="d-flex justify-content-center align-items-center mt-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        {/* <span className="ml-2">Loading...</span> */}
                    </div>
                        </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default LoadingModal;