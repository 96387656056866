import Swal from "sweetalert2";
import util from "../utils/util";



export default function useSignUp() {

    const validateEmailForUse = async (event,  inlineValidation = null ) => {
		try {
			const email = document.getElementById( "emailId" ).value;
			const pattern = /^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
			if ( email === "" ) return { result: false, msg: "Email cannot be empty" };
            const isValid = pattern.test(email);
            
			if ( !isValid ) {
				return { result: false, msg: "Invalid characters, only: A-Z, 0-9, ., _, -" };
			}
			const request = await util.axios.post( `/validation/unique_email`, { email: email } );
			const { error, msg, result } = request.data;
			if ( error ) throw msg;
			if ( inlineValidation === true ) {
				if ( !result ) {
					Swal.fire( {
						title: 'Oops!',
						text: "Email already in use",
						icon: 'error'
					});
					return { result: false, msg: "Email already in use" };
				}
			}
			return { result, msg:"Email is valid" };
        } catch (error) {
            console.log( error);
			// util.handleError( error );
		}
	};


	const cssStyleInput = "body%20%7B%0A%20%20margin%3A%200%3B%0A%20%20font-family%3A%20system-ui%2C%20-apple-system%2C%20%22Segoe%20UI%22%2C%20Roboto%2C%20%22Helvetica%20Neue%22%2C%20%22Noto%20Sans%22%2C%20%22Liberation%20Sans%22%2C%20Arial%2C%20sans-serif%2C%20%22Apple%20Color%20Emoji%22%2C%20%22Segoe%20UI%20Emoji%22%2C%20%22Segoe%20UI%20Symbol%22%2C%20%22Noto%20Color%20Emoji%22%0A%0A%7D%0Alabel%20%7B%0A%20%20font-size%3A%2012px%3B%0A%20%20font-weight%3A%20500%3B%0Acolor%3A%20%2303173a%3B%0A%7D%0A%0Aselect%3Afirst-child%20%7B%0A%20%20width%3A%20100%25%3B%0A%7D%0A%0Ainput%2C%0Aselect%20%7B%0A%20%20padding%3A%200.375rem%200.75rem%3B%0A%20%20font-size%3A%201rem%3B%0A%20%20font-weight%3A%20400%3B%0A%20%20line-height%3A%201.5%3B%0A%20%20color%3A%20%23212529%3B%0A%20%20background-color%3A%20%23fff%3B%0A%20%20background-clip%3A%20padding-box%3B%0A%20%20border%3A%201px%20solid%20%23ced4da%3B%0A%20%20-webkit-appearance%3A%20none%3B%0A%20%20-moz-appearance%3A%20none%3B%0A%20%20appearance%3A%20none%3B%0A%20%20border-radius%3A%200.25rem%3B%0A%7D%0A%0Ainput%3Afocus%20%7B%0A%20%20color%3A%20%23212529%3B%0A%20%20background-color%3A%20%23fff%3B%0A%20%20border-color%3A%20%2386b7fe%3B%0A%20%20outline%3A%200%3B%0A%20%20box-shadow%3A%200%200%200%200.25rem%20rgba%2813%2C%20110%2C%20253%2C%200.25%29%3B%0A%7D%0A%0A.error%20%7B%0A%20%20color%3A%20red%3B%0A%20%20border%3A%201px%20solid%20red%3B%0A%7D%0A"

	const phoneNumberMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
	const zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
	const routingNumber = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

    return {
		validateEmailForUse,
		cssStyleInput,
		phoneNumberMask,
		zipMask,
		routingNumber
    }
    
}