import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ANNUALY, MONTHLY } from "../shared/constants";
import { SignUpStore } from "../stores/SignUpStore";
import useSideBar from "../functions/useSideBar";


const Sidebar = () => {

    const state = SignUpStore.useState(s => s);

    const { handleSubscriptionChange } = useSideBar(state);

    return (
        <>
            <h1 className="p-3 mt-3 text-center sidebar_title text-white">
                Order Summary
            </h1>
            <div className="p-3">
                <ul className="subscriptionOptions">
                    <p className="sidebar_subtitle" >Select Subscription</p>
                    <li>
                        <div className={`pl-2 pr-2 radioOption  d-flex justify-content-between ${state.selectedSubs === MONTHLY && 'markSelectedOption'}`}>
                            <div className="d-flex align-items-center w-100">
                                <input checked={state.selectedSubs === MONTHLY ? true : false} type="radio" name="subscriptionLenght" onChange={handleSubscriptionChange} id="idRadioMonthly" value="1" />
                                <label htmlFor="idRadioMonthly">
                                    <span className="p-2">Monthly</span>
                                </label>
                            </div>
                            <label htmlFor="idRadioMonthly">
                                <div className="pl-1">$179.00</div>
                            </label>
                        </div>
                    </li>
                    <li>
                        <div className={`pl-2 pr-2 radioOption  d-flex justify-content-between ${state.selectedSubs === ANNUALY && 'markSelectedOption'}`}>
                            <div className="d-flex align-items-center">
                                <input checked={state.selectedSubs === ANNUALY ? true : false} type="radio" name="subscriptionLenght" onChange={handleSubscriptionChange} id="idRadioAnnually" value="2" />
                                <label htmlFor="idRadioAnnually"><span className="p-2">Annually</span></label>
                            </div>
                            <label htmlFor="idRadioAnnually">
                                <div className="pl-1">$2,148.00</div>
                            </label>
                        </div>
                    </li>
                    <li>
                        <div className="radioOption  d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faCheck} />
                                <span className="p-2">
                                    <span className="pr-1 false" aria-label="One Time Bank Mapping Fee">Bank Mapping Fee</span>
                                </span>
                            </div>
                            <label htmlFor="idRadioAnnually" className="pr-1 false">
                                <div className="pl-1">$55.00</div>
                            </label>
                        </div>
                    </li>
                    {/* <li>
                        <div className=" mt-5">
                            <div className="text-center mb-3"><span>Enter Disccount Code</span></div>
                            <div className="pr-1">
                                <div className="d-flex align-items-center">
                                    <input id="discountInput" value={state.code} onChange={handleCodeChange} maxLength="6" type="text" className="form-control text-center" />
                                    <div>
                                        <button onClick={validateDiscountCode} disabled={isButtinEnabled} className="m-1 btn btn-md btn-primary">
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-2 d-none text-danger" id="codeResultFail">
                                The discount code is not valid
                            </div>
                            <div className="pt-2 d-none text-success" id="codeResultSuccess">
                                The discount code is valid. Discount applied.
                            </div>
                        </div>
                    </li> */}
                </ul>
            </div>
            <div id="totalId" className="text-center sidebar_total goldColor">
                Total: ${state.total}.00 USD
            </div>
            <div className="dividerLine"></div>
            <div className="d-grid">
                {/* <div>
                    <p className="p-2 text-justify borderLight">
                        The All-Inclusive Plan includes a no-charge upgrade to the General Ledger system and all subsequent modules upon their release (available July 2024, price TBD).
                    </p>
                </div>
                <div className="d-flex justify-content-center mt-5">
                    <span>
                        Do you have an account? <a href={process.env.REACT_APP_REVREX_SITE }>login</a>
                    </span>
                </div> */}
            </div>
        </>
    );
}

export default Sidebar;

