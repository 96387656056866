const Logo = () => {
    return (
        <div className="row mb-2">
            <div className="col-md-2"></div>
            <div className="col-md-8 d-flex justify-content-start align-items-center">
                {/* LOGO */}
                <img height={120} src="/logo.png" alt="Logo revrex" />
                <div className="logoText">
                    <div>Revenue Reconciliation</div>
                    <div>Expense Classification</div>
                    <div>X-iT Strategy</div>
                </div>
            </div>
            <div className="col-md-2"></div>
        </div>
    );
}

export default Logo;