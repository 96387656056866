const ErrorBox = ({errors, touched, field}) => {
    return ( 
        <>
            <span className="formError">
                {errors[field] && touched[field] && errors[field]}
            </span>
        </>
     );
}
 
export default ErrorBox;