import Logo from "./components/Logo";
import Sidebar from "./components/Sidebar";
import SignUpForm from "./components/SignUpForm";

function App() {
  return (
    <div className="container containerMaster mt-1 mb-5">
        <Logo />
      <div className="row">
        <div className="col-md-2"></div>
        {/* SIDEBAR */}
        <div className="col-md-3 sidebar">
          <Sidebar />
        </div>
        {/* MAIN */}
        <div className="col-md-5 mainContainer">
          <SignUpForm />
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default App;
